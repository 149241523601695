const TOKEN_KEY = 'access_token'
const TENANT_DOMAIN = 'tenant_domain'

export const setToken = (token) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token))
}

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY)
  return token ? JSON.parse(token) : null
}

export const setTenant = (domain) => {
  domain
    ? localStorage.setItem(TENANT_DOMAIN, JSON.stringify(domain))
    : localStorage.removeItem(TENANT_DOMAIN)
}

export const getTenant = () => {
  const domain = localStorage.getItem(TENANT_DOMAIN)
  return domain ? JSON.parse(domain) : null
}

export const unauthenticateUser = () => {
  localStorage.clear()
}
