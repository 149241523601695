import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'

// I18n setup based on currentUser language
const ROOT = process.env.REACT_APP_ROOT_URL || ''

const backendOpts = {
  // eslint-disable-next-line no-restricted-globals
  loadPath: `${ROOT}/api/auth/locale/{{lng}}`
}

// should be dynamic in the future
const userLang = 'en'

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: backendOpts,
    lng: userLang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
      useSuspense: false
    }
  })

export default i18n
