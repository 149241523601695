import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Button from '../components/Button'
import { getToken, getTenant } from '../services/storage'

function Page404({ title, info, img, imgWidth }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const token = getToken()
  const tenant = getTenant()
  const handleClick = () => (token ? navigate(`/${tenant}/dashboard`) : navigate('/get-started'))

  return (
    <div className='flex flex-col justify-center items-center mt-32 '>
      <div className='flex flex-col justify-center items-center  w-full md:w-1/2 p-8'>
        <h3 className='text-6xl'>{t('common.oops')}</h3>
        {img && (
          <img src={process.env.PUBLIC_URL + img} className={imgWidth} alt='not-search-found' />
        )}
        {title && <h5 className=' mt-10'>{title}</h5>}
        {info && <p className='my-6 text-center'>{info}</p>}
        <Button
          handleClick={handleClick}
          title={token ? t('common.back_to_home') : t('login.login')}
          solid
          type='primary'
          icon='solar:arrow-right-linear'
          iconForward
        />
      </div>
    </div>
  )
}

export default Page404
