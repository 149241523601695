import React, { lazy } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { I18nextProvider, useTranslation } from 'react-i18next'
import i18n from './i18n/i18n'
import { getToken, getTenant } from './services/storage'
import * as routes from './constants/Routes'
import { FallbackProvider } from './composables/FallbackProvider'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'nprogress/nprogress.css'
import Page404 from './Pages/Page404'

const WithNavigation = lazy(() =>
  import('./components/NavBar/WithNavigation').catch(() => window.location.reload())
)
const Dashboard = lazy(() => import('./Pages/Dashboard').catch((e) => window.location.reload()))

const ProjectsPage = lazy(() =>
  import('./Pages/ProjectPage').catch((e) => window.location.reload())
)
const WizardPage = lazy(() => import('./Pages/WizardPage').catch((e) => window.location.reload()))

const ApplyProjectsPage = lazy(() =>
  import('./Pages/ApplyProjectsPage').catch((e) => window.location.reload())
)
const GanttChartPage = lazy(() =>
  import('./Pages/GanttChartPage').catch((e) => window.location.reload())
)
const CalendarPage = lazy(() =>
  import('./Pages/CalendarPage').catch((e) => window.location.reload())
)
const TalentsPage = lazy(() => import('./Pages/TalentsPage').catch((e) => window.location.reload()))
const MyCompanyPage = lazy(() =>
  import('./Pages/MyCompanyPage').catch((e) => window.location.reload())
)
const TimeTablePage = lazy(() =>
  import('./Pages/TimeTablePage').catch((e) => window.location.reload())
)

const ProjectApplicantPage = lazy(() =>
  import('./Pages/ProjectApplicantPage').catch((e) => window.location.reload())
)
const CoursesPage = lazy(() => import('./Pages/CoursesPage').catch((e) => window.location.reload()))
const SkillsTaxonomyPage = lazy(() =>
  import('./Pages/SkillsTaxonomy').catch((e) => window.location.reload())
)
const SkillsPage = lazy(() => import('./Pages/SkillPage').catch((e) => window.location.reload()))
const CreateProjectPage = lazy(() =>
  import('./Pages/CreateProjectPage').catch((e) => window.location.reload())
)

// const SingleCoursePage = lazy(() =>
//   import("./Pages/SingleCoursePage").catch((e) => window.location.reload())
// );

const SingleSubjectPage = lazy(() =>
  import('./Pages/SingleSubjectPage').catch((e) => window.location.reload())
)

const SingleChapterPage = lazy(() =>
  import('./Pages/SingleChapterPage').catch((e) => window.location.reload())
)

const ProfilePage = lazy(() => import('./Pages/ProfilePage').catch((e) => window.location.reload()))

const UsersPage = lazy(() => import('./Pages/UsersPage').catch((e) => window.location.reload()))

const LoginPage = lazy(() => import('./Pages/LoginPage').catch((e) => window.location.reload()))

const CheckEmailPage = lazy(() =>
  import('./Pages/CheckEmailPage').catch((e) => window.location.reload())
)

const SignupPage = lazy(() => import('./Pages/SignupPage').catch((e) => window.location.reload()))

const AddCoursePage = lazy(() =>
  import('./Pages/AddCoursePage').catch((e) => window.location.reload())
)

const ForgotPasswordEmailVerify = lazy(() =>
  import('./Pages/Auth/ForgotPasswordEmailVerify').catch((e) => window.location.reload())
)
const ForgotPassword = lazy(() =>
  import('./Pages/Auth/ForgotPassword').catch((e) => window.location.reload())
)
const ProjectDetailPage = lazy(() =>
  import('./Pages/ProjectDetailsPage/index').catch((e) => window.location.reload())
)

const AdminProjectDetailPage = lazy(() =>
  import('./Pages/SuperAdminProjectView').catch((e) => window.location.reload())
)

const SkillPage = lazy(() => import('./Pages/SkillPage').catch((e) => window.location.reload()))

// const CourseDetailsPage = lazy(() =>
//   import('./Pages/CourseDetailsPage').catch((e) => window.location.reload())
// )

const AdminTools = lazy(() => import('./Pages/AdminTools').catch((e) => window.location.reload()))

const PublicDepartmentsPage = lazy(() =>
  import('./Pages/PublicDepartmentsPage').catch((e) => window.location.reload())
)

const AllUsersPage = lazy(() =>
  import('./Pages/AllUsersPage').catch((e) => window.location.reload())
)

const ClientsPage = lazy(() =>
  import('./Pages/ClientsPage/index').catch((e) => window.location.reload())
)
const ClientsDetailsPage = lazy(() =>
  import('./Pages/ClientsPage/ClientsDetailsPage').catch((e) => window.location.reload())
)
const ActiveProjectDetails = lazy(() =>
  import('./Pages/ActiveProjectDetails').catch((e) => window.location.reload())
)

const AllProjectsPage = lazy(() =>
  import('./Pages/AllProjectsPage').catch((e) => window.location.reload())
)

function App() {
  const tenant = getTenant()

  const { t } = useTranslation()

  return (
    <FallbackProvider>
      <I18nextProvider i18n={i18n}>
        <Toaster position='top-right' reverseOrder={false} />
        <Routes>
          <Route
            path={routes.LOGIN}
            element={
              <FallbackProvider>
                <LoginPage />
              </FallbackProvider>
            }
          />

          <Route
            path={routes.GETSTARTED}
            element={
              <FallbackProvider>
                <CheckEmailPage />
              </FallbackProvider>
            }
          />
          <Route
            path={routes.FORGOTPASSWORDVERIFY}
            element={
              <FallbackProvider>
                <ForgotPasswordEmailVerify />
              </FallbackProvider>
            }
          />
          <Route
            path={routes.FORGOTPASSWORD}
            element={
              <FallbackProvider>
                <ForgotPassword />
              </FallbackProvider>
            }
          />
          <Route
            path={routes.SIGNUP}
            element={
              <FallbackProvider>
                <SignupPage />
              </FallbackProvider>
            }
          />

          <Route
            path={`/:tenant/*`}
            element={
              <Routes>
                <Route
                  path={routes.HOME}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <Dashboard />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/login-wizard'
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <WizardPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.DASHBOARD}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <Dashboard />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.PROJECTS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ProjectsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/gantt-chart'
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <GanttChartPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/timetable'
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ApplyProjectsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.CLIENTPROJECTDETAILS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <AdminProjectDetailPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.PROJECTDETAILS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ProjectDetailPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.PROJECTAPPLICANT}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ProjectApplicantPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.TIMETABLE}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ProjectDetailPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.TIMETABLE}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <TimeTablePage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.COURSES}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <CoursesPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.TALENTS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <TalentsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path={routes.SINGLESUBJECT}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <SingleSubjectPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path={routes.SINGLECHAPTER}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <SingleChapterPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ASSIGNMENTS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <Dashboard />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.CAREER}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <Dashboard />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.MYCOMPANY}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <MyCompanyPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ACHIEVEMENTS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <Dashboard />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.PROFILE}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ProfilePage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.USERS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <UsersPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.USERSAPPLICANTVIEW}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <UsersPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ADDCOURSE}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <AddCoursePage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/projects/create'
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <CreateProjectPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/projects/:projectId/edit'
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <CreateProjectPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.UNILIVE}
                  element={
                    <PrivateRoute>
                      <AddCoursePage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ADMINTOOLS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <AdminTools />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.PUBLICFACULTIES}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <PublicDepartmentsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.CALENDAR}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <CalendarPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.CLIENTS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ClientsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.SCHOOLDETAILS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ClientsDetailsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ORGANIZATIONDETAILS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ClientsDetailsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ACTIVEPROJECTDETAILS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <ActiveProjectDetails />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ALLUSERS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <AllUsersPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.SKILLS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <SkillsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={routes.ALLPROJECTS}
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <AllProjectsPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/skills-taxonomy'
                  element={
                    <PrivateRoute>
                      <FallbackProvider>
                        <SkillsTaxonomyPage />
                      </FallbackProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path='*'
                  element={
                    <FallbackProvider>
                      <Page404
                        title={t('common.page_not_found')}
                        info={t('common.page_not_found_desc')}
                      />
                    </FallbackProvider>
                  }
                />
              </Routes>
            }
          />
          <Route path='/' element={<Navigate to={`/${tenant}`} />} />
          <Route
            path='*'
            element={
              <FallbackProvider>
                <Page404
                  title={t('common.page_not_found')}
                  info={t('common.page_not_found_desc')}
                />
              </FallbackProvider>
            }
          />
        </Routes>
      </I18nextProvider>
    </FallbackProvider>
  )
}

function PrivateRoute({ children }) {
  const location = useLocation()
  const token = getToken()
  return token ? (
    <WithNavigation>{children}</WithNavigation>
  ) : (
    <Navigate to='/get-started' replace state={{ path: location.pathname }} />
  )
}

export default App
