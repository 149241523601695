import * as React from 'react'
import { Suspense, useState } from 'react'

export const FallbackContext = React.createContext({
  updateFallback: () => {}
})

export const FallbackProvider = ({ children }) => {
  const [fallback, setFallback] = useState(<div></div>)

  const updateFallback = React.useCallback((fallback_) => setFallback(() => fallback_), [])

  const renderChildren = React.useMemo(() => children, [children])

  return (
    <FallbackContext.Provider value={{ updateFallback }}>
      <Suspense fallback={fallback}>{renderChildren}</Suspense>
    </FallbackContext.Provider>
  )
}
