export const HOME = '/'
export const LOGIN = '/login'
export const FORGOTPASSWORDVERIFY = '/reset-password-verify'
export const FORGOTPASSWORD = '/reset-password/:uid/:token'
export const GETSTARTED = '/get-started'
export const SIGNUP = '/users/invitation/accept'
export const DASHBOARD = '/dashboard'
export const PROJECTS = '/projects'
export const APPLYPROJECTS = '/projects/apply'
export const TIMETABLE = '/timetable'
export const COURSES = '/courses'
export const TALENTS = '/talents'
export const ACADEMICFAMILY = '/academicfamily'
export const SINGLESUBJECT = 'courses/:courseId/subjects/:subjectId'
export const SINGLECHAPTER = 'courses/:courseId/subjects/:subjectId/chapters/:chapterId'
export const ASSIGNMENTS = '/assignments'
export const CAREER = '/career'
export const MYCOMPANY = '/company'
export const GOODCAUSE = '/good_cause'
export const ACHIEVEMENTS = '/achievements'
export const PROFILE = '/profile'
export const USERSAPPLICANTVIEW = '/projects/applicant/users/:userId/:type/:projectId'
export const PROJECTDETAILS = '/projects/:id'
export const CLIENTPROJECTDETAILS = 'clients/organization_details/:clientId/projects/:projectId'
export const USERS = '/users/:userId'
export const ADDCOURSE = '/courses/add_course'
export const COURSEDETAILS = '/courses/:id'
export const APPLYPROJECTDETAILS = '/projects/apply/:id'
export const ADMINTOOLS = '/admin_tools'
export const PUBLICFACULTIES = '/admin_tools/public_faculties'
export const CALENDAR = '/calendar'
export const CLIENTS = '/clients'
export const SCHOOLDETAILS = '/clients/school_details/:clientId'
export const ORGANIZATIONDETAILS = '/clients/organization_details/:clientId'
export const ALLUSERS = '/users'
export const UNILIVE = '/uni_live'
export const SKILLS = '/skills'
export const PROJECTAPPLICANT = '/projects/applicant/:id'
export const ALLPROJECTS = '/projects/all'
export const ACTIVEPROJECTDETAILS = '/projects/my-projects/:id/details/:projectId'
