import React from 'react'
import { Icon } from '@iconify/react'

function Button({
  title,
  icon,
  type,
  handleClick,
  small,
  solid,
  outlined,
  iconForward,
  disabled,
  styles
}) {
  let buttonStyle
  switch (type) {
    case 'primary':
      buttonStyle = `${
        outlined
          ? 'border border-blue-500 text-blue-500'
          : solid
          ? 'bg-blue-500 hover:bg-blue-600 text-white'
          : 'bg-blue-200 hover:bg-blue-300 text-blue-600'
      } `
      break
    case 'secondary':
      buttonStyle = `${
        outlined
          ? 'border border-orange-500 text-orange-500'
          : solid
          ? 'bg-orange-500 hover:bg-orange-600 text-white'
          : 'bg-orange-200 hover:bg-orange-300 text-orange-600'
      } `
      break
    case 'sucess':
      buttonStyle = `${
        outlined
          ? 'border border-green-500 text-green-500'
          : solid
          ? 'bg-green-500 hover:bg-green-600 text-white'
          : 'bg-green-200 hover:bg-green-300 text-green-600'
      } `
      break
    case 'error':
      buttonStyle = `${
        outlined
          ? 'border border-red-500 text-red-500'
          : solid
          ? 'bg-red-500 hover:bg-red-600 text-white'
          : 'bg-red-200 hover:bg-red-300 text-red-600'
      } `
      break
    default:
      buttonStyle = `${
        outlined
          ? 'border border-gray-500 text-gray-500'
          : solid
          ? 'bg-gray-600 hover:bg-gray-700 text-white'
          : 'bg-gray-200 hover:bg-gray-300 text-gray-600'
      } `
  }

  return (
    <button
      disabled={disabled}
      className={`${buttonStyle} ${styles} flex justify-center items-center font-semibold py-2 px-4 ${
        small ? ' text-xs' : 'text-sm'
      } focus:outline-none rounded-xl transition duration-500 outline-0`}
      onClick={handleClick}
      id='button'
    >
      {!iconForward && <Icon className={`text-3xl ${title && 'mr-2'} w-5 h-5`} icon={icon} />}
      {title}
      {iconForward && <Icon className={`text-3xl ${title && 'ml-2'} w-5 h-5`} icon={icon} />}
    </button>
  )
}

export default Button
